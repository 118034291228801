import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Paws from "../data/icons/BG-Paws.svg"

const Wrapper = styled.div`
position:relative;
min-height:calc(100vh - 68px);
width:100%;
height:auto;
& #BGPaws {
    display:none;
}
@media(min-width:993px) {
    height:1300px;
    font-size:24px;

    & #BGPaws {
        display:block;
        left:-40px;
        position:absolute;
        width:100%;
        height:100%;
        z-index:-1;
    }
}
display:flex;
flex-flow: row wrap;
& #left {
    mix-blend-mode:multiply;
    z-index:0;
    background-color:var(--tgrey);  
}
& #right {
    mix-blend-mode:multiply;
    z-index:0;
    background-color:var(--tmain-dark2);
}

font-family:var(--textfont);

& p {
    font-weight:light;
}

font-size:20px;


`

const Subcontainer = styled.div`
height:100%;
width:100%;
flex-grow:1;
padding:20px;
text-align:center;
color:white;
padding-top:15vh;

& h1 {
    width:90%;
    font-size:30px;
    margin:auto;
    font-family:var(--headerfont);
    letter-spacing:2px;
}
& h2 {
    font-size:22px;
    font-weight:400;
}
padding-bottom:50px;
@media(min-width:993px) {
    width:50%;
    & h1 { font-size:40px; }
    & h2 { font-size:28px; }
}
`

const TimeWrapper = styled.div`
font-weight:light;
& .times {
    font-size:24px;
    font-weight:400; 
}
@media (min-width:993px){
    
    & .times {
        font-size:32px;
    }
}
`
const Slogan = styled.p`
margin-top:150px;
font-size:34px;

`
const AdressBlock = styled.div`
& h1 {
    margin-bottom:10px;
}
& p {
    margin:10px;
}

`

const BottomItems = styled.div`

@media(min-width:993px) {
    flex-direction:row;
    margin-bottom:20px;
    position:absolute;
    align-items:baseline;
}

text-align:center;
margin-bottom:20px;
position:relative;
bottom:0;
left:0;
display:flex;
flex-direction:column;

align-items:center;
font-size:20px;
& .link {
    text-decoration:none;
    color:var(--twhite);
}
& .link:hover {
    color:var(--tmain);
    cursor:pointer;
}
& * {
    transition:0.2s;
    margin:0 20px;
}
`
const BottomItems2 = styled.p`
@media(min-width:993px) {
    position:absolute;
    bottom:20px;
    right:20px;
    
    margin-bottom:0px;
}
padding:5px;
background-color:var(--tmain-dark2);
margin-bottom:20px;
position:relative;
bottom:0;
right:0;

font-size:20px;
`

const Map = styled.img`
position:relative;
top:50px;
background-color:var(--twhite);
max-width:700px;
height:auto;
width:90%;
margin:auto;
border-radius:var(--brad);


`
class Footer extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            atBottom: false
        }
    }

    render() {
        return (
            <Wrapper atBottom={this.state.atBottom} id={this.props.id}>
                <Subcontainer id="left">
                    <h1>{this.props.data2.openingTitle}</h1>
                    <TimeWrapper>
                        {
                            this.props.data.opening.times.map((item, i) => (
                                <p key={i} className="times">{item.days} &emsp; <span>{item.time}</span></p>
                            ))
                        }
                        {
                            this.props.data.opening.details.map((item, i) => (
                                <p key={i}>{item.text}</p>
                            ))
                        }
                        <p>{this.props.data.phone.detail}</p>
                    </TimeWrapper>
                    
                    <Slogan>{this.props.data2.slogan}</Slogan>
                    
                    <BottomItems>
                        <AnchorLink className="link" to="/imprint/">Impressum</AnchorLink>
                        <p>{this.props.data2.copyright}</p>
                        <AnchorLink className="link" to="/privacy/">Datenschutz</AnchorLink>
                    </BottomItems>
                </Subcontainer>
                <Subcontainer id="right">
                    <h1>{this.props.data2.contactTitle}</h1>
                    <h2>{this.props.data2.name}</h2>
                    <h2>{this.props.data.phone.title}: <span>{this.props.data.phone.number}</span></h2>
                    <h2>{this.props.data.email.title}: {this.props.data.email.adress}</h2>
                    <AdressBlock>
                        <h1>{this.props.data.adress.title}</h1>
                        <p>{this.props.data.adress.street}</p>
                        <p>{this.props.data.adress.zipcode}</p>
                    </AdressBlock>
                    
                    <a href="https://www.google.com/maps/place/Dr.+Heike+Kudlik+Tierarzt+Praxis/@50.8016797,7.8747244,17z/data=!3m1!4b1!4m5!3m4!1s0x47bea0ef0ca7248d:0xfe64f762ff2405c7!8m2!3d50.8016732!4d7.8769205?hl=de-DE">
                        <Map src="https://maps.googleapis.com/maps/api/staticmap?center=50.8015312,7.8772067&zoom=14&size=600x400&maptype=roadmap&markers=color:red%7C50.8015312,7.8772067&key=AIzaSyBemaURcnQ3ZW-hhwaob-uUj-GrgQCRW7Q" alt="Hier finden Sie uns"/>
                    </a>
                    <BottomItems2>{this.props.data2.endorsement}</BottomItems2>
                </Subcontainer>
                <Paws id="BGPaws"/>
            </Wrapper>
        )
    }
}
/*
<Subcontainer id="right_container">
                
                    
                </Subcontainer>
                <Subcontainer id="left_container">
                    <h1>Kontakt</h1>
                    <h2>{this.props.data.phone.title}: <span>{this.props.data.phone.number}</span></h2>
                    <AdressBlock>
                        <h1>{this.props.data.adress.title}</h1>
                        <p>{this.props.data.adress.street}</p>
                        <p>{this.props.data.adress.zipcode}</p>
                    </AdressBlock>
                   
                    <Map></Map>
                    <BottomItems2>{this.props.data2.endorsement}</BottomItems2>
                    
                </Subcontainer>
                <div className="bg right"/>
                <div className="bg left"/>
                <BGPaws id="BGPaws"/>
*/
export default Footer;