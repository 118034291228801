import React from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components'
import PawNavbar from '../data/icons/pawNavbar.svg'
import Burgermenu from '../data/icons/burgermenu.svg'
import Clock from '../data/icons/clock.svg'
import Phone from '../data/icons/phone.svg'
import Cross from '../data/icons/cross.svg'
import Topbar from './Topbar'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Container = styled.div`
position:sticky;
top:-1px;
width:100%;
height:60px;
z-index:20;

@media(min-width:993px){
    height:70px;
}
`

const Mobile = styled.div`
position:relative;
width:100%;
height:100%;

display:flex;
align-items:center;
justify-content:space-between;
background-color:var(--twhite);
z-index:20;

& svg {
    width: auto;
    height: 50%;
}

@media(min-width:993px){
    display:none;
}
`

const BorderElement = styled.div`
position:absolute;
top:59px;
width:100%;
border-bottom:solid 1px #CACACA;
z-index:11;
`

const Navigation = styled.div`
width:auto;
height:100%;
display:flex;
align-items:center;

& svg {
    margin-left:15px;
    cursor:pointer;
}
`

const Title = styled(AnchorLink)`
padding-top:8px;
font-family:var(--textfont);
font-size:18px;
margin:0 20px;
text-decoration:none;
color:var(--tgrey);

& span {
    font-family:var(--headerfont);
    font-weight:bold;
    font-size:18px;
    white-space: nowrap;
}

&:hover {
    cursor:pointer;
}

@media(min-width:993px) {
    padding-top:20px;
    font-family:var(--textfont);
    font-size:24px;
    margin-left:2%;
    text-decoration:none;
    color:var(--tgrey);

    & span {
        font-family:var(--headerfont);
        font-weight:bold;
        font-size:24px;
    }
}
`

const Contact = styled.div`
width:auto;
height:80%;
display:flex;
align-items:center;
background-color:var(--tmain-light);
border-radius:var(--brad);
padding:0 10px;
margin-right:5px;

& svg:first-child {
    margin-right:5px;
}

& svg:last-child {
    margin: 0 5px;
}

&:hover {
    cursor:pointer;
}
`

const NavbarDropdown = styled.div`
position:absolute;
top:59px;
width:100%;
height:${props => props.dropdown==1?(props.buttonCount*41.2):("0")}px;
overflow:hidden;
display:flex;
flex-direction:column;
align-items:flex-start !important;
font-family:var(--textfont);
font-size:18px;
background-color:var(--twhite);
border-bottom:solid 1px #CACACA;
transition:height 0.6s;
z-index:0 !important;

& a{
    margin: 5px 0;
    padding: 2.5px;
    margin-left:30px;
    text-decoration:none;
    color:var(--tgrey);
}

& a:first-child{
    margin-top:20px;
}

& a:last-child{
    margin-bottom:20px;
}
`


const ContactDropdown = styled(AnimateHeight)`
position:absolute;
top:59px;
width:100%;
font-family:var(--textfont);
font-size:18px;
text-align:center;
background-color:var(--twhite);
border-bottom:solid 1px #CACACA;
z-index:-1 !important;

transition: all .6s ease;
`

const Desktop = styled.div`
position:relative;
display:none;
width:100%;
height:100%;
align-items:center;
justify-content:space-between;
background-color:var(--twhite);
border-bottom:solid 1px #CACACA;
z-index:20;

& >p {

}

& >div {
    width:60%;
    display:flex;
    justify-content:space-around;
    font-family:var(--textfont);
    font-size:20px;
}

@media(min-width:993px){
    display:flex;
}
`

const NavButton = styled(AnchorLink)`
display:flex;
flex-direction:column;
align-items:center;
text-decoration:none;
color:var(--tgrey);

& svg{
    margin-left:5px;
    transform: rotateZ(40deg);
    opacity:0;
    transition: all 0.3s;
}

&:hover {
    cursor:pointer;
    
    & svg{
        opacity:1;
    }
}
`

const PhoneContainer = styled.div`
& h2 {
    font-size:24px;
}

& .number {
    font-family:Arial;
    font-size:18px;
    margin:0;
}
`

const OpenContainer = styled.div`
& h2 {
    font-size:24px;
}

& .times {
    font-family:var(--textfont);
    font-weight:600;
    font-size:18px;
    margin:0;

    & span {
        font-family:Arial;
        font-weight:500;
    }
}
`

const ClosingDiv = styled.div`
position:fixed;
top:0;
left:0;
width:100vw;
height:100vh;
z-index:-1;

display: ${props => props.dropdown?('block'):('none')};
`


class Navbar extends React.Component{
    constructor(props){
        super(props);

        this.state={
            dropdown: false,
            dropdownContact: false,
            height: 0,
        }
    }
     
    toggle = () => {
    const { height } = this.state;
    
    this.setState({
        height: height === 0 ? 'auto' : 0,
        dropdownContact: !this.state.dropdownContact,
        dropdown: false,
    });
    };

    toggledropdown(){
        this.setState({dropdown: !this.state.dropdown, height: 0, dropdownContact: false})
    }

    closeAll(){
        this.setState({dropdown: false, height: 0, dropdownContact: false})
    }
    
    render(){

        const { height } = this.state;

        return(
            <Container>
            <Mobile>
                <Navigation>
                    { !this.state.dropdown && <Burgermenu onClick={this.toggledropdown.bind(this)}/> }
                    { this.state.dropdown && <Cross onClick={this.toggledropdown.bind(this)}/> }
                    <Title to="/#top"><div dangerouslySetInnerHTML={{__html:this.props.data.title}}/></Title>
                </Navigation>
                <Contact aria-expanded={ height !== 0 } aria-controls='topbar-panel' onClick={ this.toggle.bind(this) }>
                    <Clock/>
                    <Phone/>
                </Contact>
                <BorderElement/>
                <NavbarDropdown dropdown={this.state.dropdown?(1):(0)} buttonCount={this.props.data.navigationButtons.length}>
                    {
                        this.props.data.navigationButtons.map((item, i) => (
                            <AnchorLink key={i} onAnchorLinkClick={this.closeAll.bind(this)} to={item.link}>{item.title}</AnchorLink>
                        ))
                    }
                </NavbarDropdown>
                <ContactDropdown
                    id='topbar-panel' 
                    duration={ 550 } 
                    height={ height } // see props documentation below 
                >
                    <OpenContainer>
                        <h2>{this.props.topbar.opening.title}</h2>

                        {
                            this.props.topbar.opening.times.map((item, i) => (
                                <p key={i} className="times">{item.days} &emsp; <span>{item.time}</span></p>
                            ))
                        }
                        {
                            this.props.topbar.opening.details.map((item, i) => (
                                <p key={i} className="detail">{item.text}</p>
                            ))
                        }
                    </OpenContainer>
                    <PhoneContainer>
                        <h2>{this.props.topbar.phone.title}</h2>
                        <p className="number">{this.props.topbar.phone.number}</p>
                        <p className="detail">{this.props.topbar.phone.detail}</p>
                    </PhoneContainer>
                </ContactDropdown>
                <ClosingDiv dropdown={this.state.dropdown || this.state.dropdownContact} onClick={ this.closeAll.bind(this) }/>
            </Mobile>
            <Desktop>
                <Title to="/#top"><div dangerouslySetInnerHTML={{__html:this.props.data.title}}/></Title>
                <div>
                    {
                        this.props.data.navigationButtons.map((item, i) => (
                            <NavButton key={i} to={item.link}><PawNavbar/> {item.title}</NavButton>
                        ))
                    }
                </div>
                <Topbar data={this.props.topbar}/>
            </Desktop>
        </Container>
        )
    }
}

export default Navbar;