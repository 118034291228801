import React from "react"
import styled from "styled-components"

const Container = styled.h1`
position:relative;
width:100%;
height:${props => props.height!=null?((props.height/2) + props.unit):("25px")};

@media(min-width:993px) {
    height:${props => props.height!=null?(props.height + props.unit):("50px")};
}
`

export default function Spacer(props) {
    return(
        <Container height={props.height} unit={props.unit}/>
    )
}