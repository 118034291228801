import React from "react"
import styled from "styled-components"

const Container = styled.h1`
position:relative;
margin:auto;
width:90%;
max-width:500px;
text-align:center;
font-family:var(--headerfont);
font-size:1.875em;
color:var(--tgrey);
&:after{
    content: '';
    position: absolute;
    left: 35%; 
    bottom: 2.5px;
    height: 3px;
    width:30%;
    background-color: var(--tmain);
    border-radius:200px;
}
@media(min-width:993px){
    font-size:2.25em;
}
`

export default function Header(props) {
    return(
        <Container>
            {props.children}
        </Container>
    )
}