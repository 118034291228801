import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components'


const Container = styled.div`
position:absolute;
display:${props => props.atBottom?("none"):("flex")} !important;
top:70px;
left:20px;
width:18.75em !important;
display:block;
flex-flow:column-reverse;
justify-content:flex-start !important;
text-align:center;
border-radius:0 0 20px 20px;

background-color:var(--tmain-light);
color:var(--tgrey);

&:hover {
    cursor:pointer;
}

z-index:-1;

@media(min-width:993px){
    display: block;
}
`

const Main = styled.div`

`

const Expandable = styled(AnimateHeight)`
& h2 {
    font-family:var(--headerfont);
    font-size:28px;

    margin: 10px 0;
}

& .detail {
    font-family:var(--textfont);
    font-size:16px;
}
`

const PhoneContainer = styled.div`

& .number {
    font-family:Arial;
    font-size:24px;
    margin:0;
}
`

const OpenContainer = styled.div`
& .times {
    font-family:var(--textfont);
    font-weight:600;
    font-size:24px;
    margin:0;

    & span {
        font-family:Arial;
        font-weight:500;
    }
}
`

class Topbar extends Component {
    constructor(props){
        super(props)

        this.state={
            height: 0,
            atBottom: false,
        }
    }
     
    toggle = () => {
    const { height } = this.state;
    
    this.setState({
        height: height === 0 ? 'auto' : 0,
    });
    };

    render(){
        const { height } = this.state;

        return (
            <Container aria-expanded={ height !== 0 } aria-controls='topbar-panel' onMouseEnter={ this.toggle } onMouseLeave={ this.toggle } atBottom={this.state.atBottom}>
                <Main>
                    <p>{this.props.data.title}</p>
                </Main>
                <Expandable 
                    id='topbar-panel' 
                    duration={ 500 } 
                    height={ height } // see props documentation below 
                >
                    <OpenContainer>
                        <h2>{this.props.data.opening.title}</h2>

                        {
                            this.props.data.opening.times.map((item, i) => (
                                <p key={i} className="times">{item.days} &emsp; <span>{item.time}</span></p>
                            ))
                        }
                        {
                            this.props.data.opening.details.map((item, i) => (
                                <p key={i} className="detail">{item.text}</p>
                            ))
                        }
                    </OpenContainer>
                    <PhoneContainer>
                        <h2>{this.props.data.phone.title}</h2>
                        <p className="number">{this.props.data.phone.number}</p>
                        <p className="detail">{this.props.data.phone.detail}</p>
                    </PhoneContainer>
                </Expandable>
            </Container>
        )
    }
}

export default Topbar;