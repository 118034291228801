import React from 'react'
import styled from 'styled-components'
import Phone from '../data/icons/phone.svg'
import Clock from '../data/icons/clock.svg'

const Container = styled.div`
position:fixed;
display:none;
top:65%;
right:0;
color:var(--tgrey);

z-index:10;

@media(min-width:993px){
    display:${props => props.atBottom?("none"):("block")};
}
`

const PhoneContainer = styled.div`
display:flex;
align-items:center;

border-radius: 10px 0 0 10px;
margin-bottom:15px;

background-color:var(--tmain-light);

transform:translateX(calc(100% - 60px));
transition: transform 0.5s ease; 

& svg {
    width:60px;
    height:60px;
    padding:10px;
}

& p {
    font-family:var(--textfont);
    font-size:24px;
    font-weight:600;
    margin: 0 30px;
}

&:hover {
    cursor:pointer;
    transform:translateX(0);
}
`

const OpenContainer = styled.div`
display:flex;
flex-direction:column;
border-radius: 10px 0 0 10px;
padding-right: 15px;
overflow:hidden;
height:${props => props.heightFold!=0?(props.heightFold):("")}px;

transform:translateX(calc(100% - 60px));
transition: transform 0.4s 0.3s ease, height 0.3s ease;

background-color:var(--tmain-light);

& svg {
    width:60px;
    height:60px;
    padding:10px;
}

& div {
    & h2 {
        text-align:center;
        font-family:var(--textfont);
        font-size:28px;
        font-weight:600;
    }

    & p {
        text-align:center;
        font-family:var(--textfont);
    }
}

& .times {
    font-family:var(--textfont);
    font-weight:600;
    font-size:24px;
    margin:0;

    & span {
        font-family:Arial;
        font-weight:500;
    }
}

&:hover {
    cursor:pointer;
    transform:translateX(0);
    height:${props => props.heightFold!=0?(props.heightUnfold):("")}px;

    transition: transform 0.4s ease, height 0.3s 0.4s ease;
}
`

const Main = styled.div`
display:flex;
align-items:center;

& p{
    font-family:var(--textfont);
    font-size:24px;
    font-weight:600;
    margin: 0 30px;
}
`

const Expandable = styled.div`
padding-left:15px;
`


class Sidebar extends React.Component {
    constructor(props){
        super(props)
        this.openRef = React.createRef();
        this.openRefTwo = React.createRef();
        this.state = {
            heightFold: 0,
            heightUnfold: 0,
            atBottom: false,
        }
    }

    componentDidMount(){
        const heightFold = this.openRefTwo.current.clientHeight
        const heightUnfold = this.openRef.current.clientHeight
        this.setState({ heightFold: heightFold, heightUnfold: heightUnfold })
    }

    render() {
        return (
            <Container atBottom={this.state.atBottom}>
                <PhoneContainer>
                    <Phone/>
                    <p>{this.props.data.phone.number}</p>
                </PhoneContainer>
                <OpenContainer heightFold={this.state.heightFold} heightUnfold={this.state.heightUnfold} ref={this.openRef}>
                    <Main ref={this.openRefTwo}>
                        <Clock/>
                        <p>{this.props.data.opening.title}</p>
                    </Main>
                    <Expandable>
                        {
                            this.props.data.opening.times.map((item, i) => (
                                <p key={i} className="times">{item.days} &emsp; <span>{item.time}</span></p>
                            ))
                        }
                        {
                            this.props.data.opening.details.map((item, i) => (
                                i == 0 && <p key={i}>{item.text}</p>
                            ))
                        }
                    </Expandable>
                </OpenContainer>
            </Container>
        )
    }
}

export default Sidebar;